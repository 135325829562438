import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { RouteComponentProps, Router } from "@reach/router";
import { Link } from "gatsby";
import React from 'react';
import Home from '../../components/app/home';
import PageLayout from '../../components/layout/PageLayout';

type AppProps = {
  some: string
}

const Settings = (_props: RouteComponentProps) => <p>Settings</p>
const Billing = (_props: RouteComponentProps) => <p>Billing</p>

const App: React.FC<AppProps> = ({}) => {
  const { logout } = useAuth0();
  return (
    <PageLayout>
    <nav>
      <Link to="/app">Home</Link>{" "}
      <Link to="/app/settings">Settings</Link>{" "}
      <Link to="/app/billing">Billing</Link>{" "}
      <a
        href="#logout"
        onClick={e => {
          logout({ returnTo: process.env.GATSBY_AUTH0_LOGOUTURL })
          e.preventDefault();
        }}
      >
        Log Out
      </a>
    </nav>
    <Router>
      <Home path="/app" />
      <Settings path="/app/settings" />
      <Billing path="/app/billing" />
    </Router>
  </PageLayout>
)};

const RedirectPlaceholder = () => {
  return (
    <div style={{ margin: "20px" }}>
      Redirigiendo a la página de login...
    </div>
  );
};
export default withAuthenticationRequired(App, {
  onRedirecting: RedirectPlaceholder
});