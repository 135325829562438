import { useAuth0 } from "@auth0/auth0-react";
import { RouteComponentProps } from "@reach/router";
import { Link } from "gatsby";
import React, { useState } from "react";

const Home = (_props: RouteComponentProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [token, setToken ] = useState(null as unknown as string);

  // useEffect( () => {
  //   if(token){
  //   }else{
  //     retrieveToken();
  //   }
  // },[token]);
  
  // const retrieveToken = async () => {
  //   const token = await getAccessTokenSilently();
  //   setToken(token);
  // }
  
  return <>
  <p>Hey there, {(user && user.name) ? user.name : "friend"}!</p>
  <br/>
  <Link to="/">Get out of the App</Link>
  </>;
}
export default Home;